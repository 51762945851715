/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const Man = ({myclass}) => (
  <svg className={myclass} width={300} height={480.997} viewBox="0 0 588.084 942.889">
    <defs>
      <style>
        {
          ".a,.b,.n,.p{fill:#fff;}.a,.p{stroke:#3f4d90;}.a,.i,.j{stroke-linejoin:round;}.a,.i,.j,.p{stroke-width:5px;}.b,.o{stroke:#d8baa3;stroke-width:6px;}.b,.o,.p{stroke-miterlimit:10;}.c{fill:#c36f0b;}.d{fill:#1f2041;}.e{fill:#3f4d79;}.f{fill:#5a4fec;}.g{fill:#f0e1d4;}.h{fill:#faf61d;}.i,.j,.o{fill:none;}.i,.j{stroke:#fff;}.j{stroke-linecap:round;}.k{fill:#b65a18;}.l{fill:#722d54;}.m{fill:#d8baa3;}"
        }
      </style>
    </defs>
    <g transform="translate(187.492 306.851)">
      <rect
        className="a"
        width={257.444}
        height={17.261}
        transform="translate(31.558 282.879)"
      />
      <path
        className="b"
        d="M681.576,684.539c0-158.564,71.476-245.819,71.476-245.819H540.586S469.11,525.975,469.11,684.539Z"
        transform="translate(-358.795 -438.72)"
      />
      <path
        className="b"
        d="M328.986,790.2H618.121c0,20.472-15.064,37.067-33.646,37.067H295.34C295.34,806.8,310.4,790.2,328.986,790.2Z"
        transform="translate(-295.34 -544.381)"
      />
      <path
        className="a"
        d="M711.8,1196.673h0a3.736,3.736,0,0,1-3.561-3.238L651.67,867.88H684.1l31.31,324.373a4.262,4.262,0,0,1-.908,3.1A3.486,3.486,0,0,1,711.8,1196.673Z"
        transform="translate(-425.46 -567.734)"
      />
      <path
        className="a"
        d="M347.994,1196.673h0a3.736,3.736,0,0,0,3.561-3.238l56.57-325.555H375.7l-31.316,324.373a4.262,4.262,0,0,0,.908,3.1A3.486,3.486,0,0,0,347.994,1196.673Z"
        transform="translate(-313.243 -567.734)"
      />
    </g>
    <g transform="translate(91.752)">
      <g transform="translate(37.754 455.407)">
        <path
          className="c"
          d="M325.7,1281.214c7.021-1.748,16.728-5.5,22.137-13.393l3.073-18.261s-20.188,2.532-38.63.587l3.574,23.9a9.1,9.1,0,0,0,3.534,5.9A7.551,7.551,0,0,0,325.7,1281.214Z"
          transform="translate(-243.54 -831.029)"
        />
        <g transform="translate(0 432.06)">
          <path
            className="d"
            d="M204,1309.389a77.445,77.445,0,0,0,2.33,7.833l113.553-4.238v-6.994a68.909,68.909,0,0,0-5.523-26.206c-2.946-6.777-7.034-5.084-7.034-5.084s-11.1,11.959-23.559,11.959c-2.4,0-4.12-1.4-5.352-3.441a17.134,17.134,0,0,1-1.7-4.07,36.009,36.009,0,0,1-1.092-5.735,5.17,5.17,0,0,0-2.456-3.913,4.292,4.292,0,0,0-4.318-.025c-4.888,2.8-10.824,8.966-26.72,18.422-.794.476-1.619.958-2.47,1.455C220.328,1300.555,204.216,1296.387,204,1309.389Z"
            transform="translate(-204 -1268.905)"
          />
          <path
            className="e"
            d="M216.832,1339.538a12.976,12.976,0,0,1-9.453-4.294,16,16,0,0,1-2.952-12.694h.089c.939,5.453,5.21,9.44,10.246,9.568l98.444-3.175c3.054,0,5.669-2.329,6.736-5.6v7.288c0,4.931-3.244,8.924-7.25,8.924Z"
            transform="translate(-204.053 -1285.031)"
          />
          <path
            className="e"
            d="M257.33,1298.142c11.842,2.36,21.412,11.981,24.625,24.758l4.533-.175c-2.92-13.113-11.728-23.668-23.343-27.975l-1.536.916c-.794.476-1.619.958-2.47,1.455Q258.238,1297.659,257.33,1298.142Z"
            transform="translate(-223.474 -1276.674)"
          />
          <path
            className="e"
            d="M239.64,1305.1v.651c11.8.015,22.473,7.72,27.2,19.632l4.71-.175c-4-11.63-12.941-20.341-23.908-23.282A76.426,76.426,0,0,1,239.64,1305.1Z"
            transform="translate(-217.015 -1278.83)"
          />
        </g>
        <path
          className="c"
          d="M542.644,1280.507c7.021-1.748,16.087-5.5,21.5-13.393l1.809-19.779a213.214,213.214,0,0,1-37.379-.126l4.234,26.129a9.1,9.1,0,0,0,3.53,5.9A7.546,7.546,0,0,0,542.644,1280.507Z"
          transform="translate(-322.522 -830.323)"
        />
        <g transform="translate(144.386 432.061)">
          <path
            className="d"
            d="M431.44,1309.389a77.446,77.446,0,0,0,2.33,7.833l107.134-4.238v-6.994a69.055,69.055,0,0,0-5.517-26.206c-2.952-6.777-7.034-6.5-7.034-6.5s-11.11,13.372-23.565,13.372c-2.4,0-4.12-1.4-5.345-3.441a16.9,16.9,0,0,1-1.7-4.07,35.314,35.314,0,0,1-1.092-5.735,5.176,5.176,0,0,0-2.46-3.913,4.3,4.3,0,0,0-4.32-.025c-4.888,2.8-4.406,8.966-20.315,18.422-.794.476-1.619.958-2.469,1.455C447.762,1300.556,431.649,1296.387,431.44,1309.389Z"
            transform="translate(-431.44 -1268.905)"
          />
          <path
            className="e"
            d="M444.262,1339.538a12.976,12.976,0,0,1-9.453-4.294,16,16,0,0,1-2.952-12.694h.089c.939,5.453,5.21,9.44,10.246,9.568l92.051-3.175c3.054,0,5.663-2.329,6.729-5.6v7.288c0,4.931-3.244,8.924-7.25,8.924Z"
            transform="translate(-431.489 -1285.032)"
          />
          <path
            className="e"
            d="M484.76,1298.142c11.842,2.36,21.412,11.981,24.625,24.758l4.533-.175c-2.92-13.113-11.728-23.668-23.343-27.975l-1.536.916c-.794.476-1.619.958-2.47,1.455Q485.668,1297.659,484.76,1298.142Z"
            transform="translate(-450.911 -1276.675)"
          />
          <path
            className="e"
            d="M467.07,1305.1v.651c11.8.015,22.473,7.72,27.2,19.632l4.71-.175c-4-11.63-12.941-20.341-23.908-23.282A76.429,76.429,0,0,1,467.07,1305.1Z"
            transform="translate(-444.451 -1278.83)"
          />
        </g>
        <path
          className="f"
          d="M459.473,783.922s45.835,71.931,5.606,281.851c0,0-21.083,7.791-54.685,2.1,0,0-45.492-204.115-51.682-292.558-11.16-.364-22.721-.588-34.636-.588,0,0,48.076,108.257,1.409,294.873,0,0-34.376,2.707-52.45-.133,0,0-55.478-305.119-51.891-333.493,4.291-34.151,101.941-77.757,163.368-83.9l137.479-.937s35.24,39.165,20.531,94.731C531.489,787.551,459.473,783.922,459.473,783.922Z"
          transform="translate(-210.2 -651.13)"
        />
      </g>
      <g transform="translate(180.982 125.012)">
        <path
          className="g"
          d="M613.621,668.457a13.653,13.653,0,0,0,2.019,4.091s-120.472,45.18-171.17-11.659C499.554,682.13,589.948,671.723,613.621,668.457Z"
          transform="translate(-435.04 -323.669)"
        />
        <g transform="translate(-180.982 36.787)">
          <path
            className="h"
            d="M249.035,475.614c86.972,23.08,130.547-2.923,138.444-18.785,0,0-4.349-29.78-1.136-75.946S382.8,231.32,382.8,231.32c-19.166,8.12-47.213,21.114-55.561,48.18s-9.662,101.221-10.157,132.924c0,0-44.622,16.862-64.613,28.374a13.7,13.7,0,0,0-6.348,13.778Z"
            transform="translate(-181.578 -231.32)"
          />
          <path
            className="i"
            d="M371.108,489.594s-8.665-3.791-25.888.4"
            transform="translate(-217.815 -308.489)"
          />
          <g transform="translate(0 211.498)">
            <path
              className="c"
              d="M216.869,549.626a18.378,18.378,0,0,1-6.412,17.037l-1,.776c-6.812,5.182-19.877,9.372-22.975,9.141s-22.143-4.112-22.143-4.112-.679-2.86,2.108-6.63,23.2-.776,15.738-9.05c-3.32-3.679-19.858,4.623-27.05,13.827s-10.608,1.909-10.608,1.909,6.888-17.051,9.345-20.135,18.886-16.184,29.99-18.184c5.295-.951,12.735-.413,20.124,1C210.633,536.5,215.8,542.286,216.869,549.626Z"
              transform="translate(-144.53 -533.727)"
            />
          </g>
        </g>
        <path
          className="h"
          d="M557.347,178.72c6.259,5.665,83.4,54.922,94.457,61.692s-51.561,155.354-49.612,208.367a351.521,351.521,0,0,0,11.249,73.869s-110.518,17.925-174.4-6.707c0,0,13.268-21.681,5.9-66.581C438.97,412.929,426.813,311.8,430.2,272.892c1.232-14.079,3.441-48.992,19.68-57.384C474.451,202.8,491.4,191.728,491.4,191.728Z"
          transform="translate(-429.616 -178.72)"
        />
        <path
          className="i"
          d="M446.779,501.051C440.8,464.62,429,392.5,437.821,343.69"
          transform="translate(-431.458 -228.313)"
        />
      </g>
      <g transform="translate(184.86 185.716)">
        <path
          className="h"
          d="M689.627,266.911c32.922,31.941,36.059,163.921,15.236,187.973-40.782,47.1-163.882,46.565-163.882,46.565s-12.881-2.049-5.51-35.067c0,0,91.264-50.775,100.107-50.474,0,0-1.473-94.193,17.2-122.336S685.754,263.155,689.627,266.911Z"
          transform="translate(-471.317 -265.517)"
        />
        <path
          className="j"
          d="M647.789,586.88c-48.323,13.533-102.329,11.889-102.329,11.889"
          transform="translate(-475.796 -362.125)"
        />
        <path
          className="i"
          d="M536.78,489.888s48.514-30,100.107-50.474c0,0,2.146-72.176,7.574-93.353"
          transform="translate(-472.626 -289.73)"
        />
        <g transform="translate(0 193.04)">
          <path
            className="c"
            d="M501.763,548.854s-27.552-11.463-36.458-5.7c-9.745,6.294-27.787,26.416-28.84,29.8s-.73,6.917-.54,10.554,33.2,3.574,37.112,3.322,25.349,2.3,28.967-2.028C515.3,568.933,501.763,548.854,501.763,548.854Z"
            transform="translate(-435.724 -541.533)"
          />
        </g>
      </g>
      <g transform="translate(228.708)">
        <path
          className="k"
          d="M585.334,110.77s-7.37,20.282,5.529,47.558c0,0-17.693,27.758-45.708,27.584-36.185-.217-20.27-14.61-20.27-14.61s5.529-9.749,4.444-27.206Z"
          transform="translate(-510.859 -33.281)"
        />
        <path
          className="c"
          d="M573.614,89.522c-.736,3.791-.844,20.156-6.983,24.758-7.948,5.959-31.335,15.3-49.022,8.267-2.266-.9-13.516-37.354-12.779-49.8s.984-56.566,26.028-67.665,55.027-4.9,63.382,27.6c3.415,13.288.6,25.178-3.93,34.626a10.883,10.883,0,0,1-1.746,11.421C583.518,84.948,573.614,89.522,573.614,89.522Z"
          transform="translate(-504.795 0.017)"
        />
        <path
          className="l"
          d="M593.746,67.294c-5.891-7.337-14,5.707-14,5.707l-.114.7a15.987,15.987,0,0,1-5.5-5.546c-3.39-5.651-3.669-11.938-3.219-34.549,0,0-32.891,3.826-56.741-2.49,3.663-11.281,9.783-21.485,20.124-26.066,25.057-11.1,55.034-4.9,63.382,27.6C601.091,45.934,598.273,57.845,593.746,67.294Z"
          transform="translate(-508.219 0.026)"
        />
        <g transform="translate(1.686 116.955)">
          <path
            className="g"
            d="M570.83,192.147c4.583-2.343,23.584-9.966,37.011-24.947,0,0,13.4,14.316,13.522,16.862S592.979,207.9,584.955,208.8Z"
            transform="translate(-530.595 -167.2)"
          />
          <path
            className="g"
            d="M519.823,186.43c1.27,2.525,4.482,8.483,13.655,11.19,0,0-6.717,12.085-12.944,15.876,0,0-10.157-4.9-12.919-11.274C505.787,198.019,519.823,186.43,519.823,186.43Z"
            transform="translate(-507.451 -172.981)"
          />
          <path
            className="m"
            d="M544.572,202.43l6.59,11,7.618-10.694,6.983,8.225s-9.738,5.112-27.933,1.448Z"
            transform="translate(-518.545 -177.791)"
          />
        </g>
      </g>
    </g>
    <g transform="translate(0 422.445)">
      <path
        className="b"
        d="M0,604H345.844c7.012,0,12.7,6.262,12.7,13.988v3.707c0,7.725-5.684,13.988-12.7,13.988H.635"
        transform="translate(0 -604)"
      />
      <path
        className="n"
        d="M429.96,1138.279V650.02h27.622v488.245"
        transform="translate(-157.007 -617.834)"
      />
      <path
        className="g"
        d="M429.96,659.727V650.02h27.622v33.745Z"
        transform="translate(-157.007 -617.834)"
      />
      <path
        className="o"
        d="M429.96,1137.573V650.02h27.622v487.539"
        transform="translate(-157.007 -617.834)"
      />
    </g>
    <g transform="translate(89.97 303.088)">
      <path
        className="p"
        d="M336.287,552.7H198a11.445,11.445,0,0,1-10.354-7.189l-44.7-102.894a7.051,7.051,0,0,1,.329-6.251,5.773,5.773,0,0,1,4.978-3.022H286.541a11.445,11.445,0,0,1,10.354,7.189l44.7,102.908a7.05,7.05,0,0,1-.337,6.241,5.774,5.774,0,0,1-4.97,3.019Z"
        transform="translate(-141.959 -433.34)"
      />
      <path
        className="g"
        d="M352.361,579.06H216.3a11.136,11.136,0,0,1-9.624-6.952L187,526.753l.3-.573H336.484l.248.566,18.823,43.362C358.4,575.172,356.545,579.06,352.361,579.06Z"
        transform="translate(-158.256 -461.249)"
      />
      <path
        className="a"
        d="M321.556,525.86l-25.3,48.663a11.6,11.6,0,0,1-10.36,6H147.612c-4.33,0-7.174-4.154-5.307-7.749l24.4-46.915Z"
        transform="translate(-141.722 -461.153)"
      />
    </g>
  </svg>
);

export default Man;
