import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Row from "react-materialize/lib/Row";
import Col from "react-materialize/lib/Col";
import {Link} from "gatsby";
import Man from "../images/man";

const About = () => (
  <Layout>
    <SEO title="Development, IT Support & Outsourcing, Design, and more" />
    <div className="page-container services">
      <h2 className="page-header color-0 center">About Us</h2>
      <section id="app-dev">
        <div className="container2">
          <Row>
            <Col l={6} m={6} s={12}>
              <p className="section-body2 color-1">
                BizHighTech is a partner of choice to help you tackle technology challenges in your business. We will help you increase your business value with smart, reliable, and scalable solutions. With a team of professionals with extensive experience in various fields, we provide accurate insight and world-class delivery to ensure the success of your project. At Bizhightech we are committed to success therefore your investment in your technology infrastructure will not be wasted. We offer quality at an affordable price so you won't go broke while hiring us <span role="img" aria-label="Smile face">😀</span>. Contact us today and let us help you accomplish greatness!!!
              </p>
            </Col>
            <Col className="center" l={6} m={6} s={12}>
              <Man />
            </Col>
          </Row>
        </div>
      </section>
      <div className="center cta-contain">
        <h4 className="color-0">The sky is the limit <span role="img" aria-label="Star" >⭐</span>! And it's where we will take you <span role="img" aria-label="Smile face" >😀</span>.</h4>
        <Link to="/contact">
          <button className="mybtn-border color-2-bg color-1">Contact Us Today</button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default About
